import { Component, OnInit, Input } from '@angular/core';
import { formatNumber } from '@angular/common';
import { Product } from '../../../../models/product';

@Component({
  selector: 'gob-list-line',
  templateUrl: './list-line.component.html',
  styleUrls: ['./list-line.component.css']
})
export class ListLineComponent implements OnInit {
  @Input() products: Product[];

  constructor() { }

  ngOnInit(): void {
  }

  formatPrice(price: number){
    return formatNumber(price, 'en', '1.2-2');
  }
}
