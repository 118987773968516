<article class="block green no-paddng">
	<div class="text-container">
		<div class="timer-container">
			<gob-timer [endDate]="time"></gob-timer>
			<header>
				<h2 class="title light">Ventes Privées</h2>
			</header>
			<div class="text">
				Accédez en exclusivité à nos ventes privées et profitez d'offres légendaires réservées aux initiés !<br/>
			</div>
			<div class="button">
				<a class="border-gradient btn btn-light">En savoir +</a>
			</div>
		</div>
	</div>
	<div class="img-container">
		<img src="/assets/img/skin/private-sells.jpg" alt="ventes privées"/>
	</div>
</article>
