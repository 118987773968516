import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'gob-stars',
  templateUrl: './stars.component.html',
  styleUrls: ['./stars.component.css']
})
export class StarsComponent implements OnInit {
  @Input() stars: number=0;

  constructor() { }

  ngOnInit(): void {
  }

}
