import { Component, OnInit } from '@angular/core';
import { Product } from '../../../../models/product';

@Component({
  selector: 'gob-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.css']
})
export class ProductPageComponent implements OnInit {
  product: Product;
  indImg: number=0;

  constructor() { 
    this.product=Product.list()[0];
  }

  ngOnInit(): void {
  }
}
