import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../../services/user.service';

@Component({
  selector: 'gob-log-form',
  templateUrl: './log-form.component.html',
  styleUrls: ['./log-form.component.css']
})
export class LogFormComponent implements OnInit {
  @Output() readonly signup: EventEmitter<boolean>=new EventEmitter<boolean>();
  @Output() readonly forgot: EventEmitter<boolean>=new EventEmitter<boolean>();
  error: string;

  constructor(private userServ: UserService, private router: Router) { }

  ngOnInit(): void {
  }

  login(mail: string, pass: string){
    this.userServ.login(mail, pass)
      .subscribe({
        next: () => this.router.navigate(['/login-home']),
        error: (error) => this.error=error
      });
  }
}
