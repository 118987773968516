import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gob-actu',
  templateUrl: './actu.component.html',
  styleUrls: ['./actu.component.css']
})
export class ActuComponent implements OnInit {
  time: Date;

  constructor() {
    this.time=new Date('2024-09-01 10:00:00');
  }

  ngOnInit(): void {
  }

}
