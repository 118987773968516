import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gob-form-news',
  templateUrl: './form-news.component.html',
  styleUrls: ['./form-news.component.css']
})
export class FormNewsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
