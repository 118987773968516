import { Component, OnInit, Input } from '@angular/core';
import { Product } from '../../../../models/product';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'gob-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.css']
})
export class ProductCardComponent implements OnInit {
  @Input() product: Product;

  constructor() { }

  ngOnInit(): void {
  }

  formatPrice(price: number){
    return formatNumber(price, 'en', '1.2-2');
  }
}
