import { Component, OnInit, Input } from '@angular/core';
import { formatNumber } from '@angular/common';
import { Product } from '../../../../models/product';

@Component({
  selector: 'gob-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {
  @Input() product: Product;

  constructor() { }

  ngOnInit(): void {
  }

  formatPrice(price: number){
    return formatNumber(price, 'en', '1.2-2');
  }

}
