import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TimerComponent } from './timer/timer.component';
import { PaginationComponent } from './pagination/pagination.component';
import { StarsComponent } from './stars/stars.component';
import { YoutubeComponent } from './youtube/youtube.component';
import { BackComponent } from './back/back.component';



@NgModule({
  declarations: [
    TimerComponent,
    PaginationComponent,
    StarsComponent,
    YoutubeComponent,
    BackComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    TimerComponent,
    PaginationComponent,
    StarsComponent,
    YoutubeComponent,
    BackComponent
  ]
})
export class UiModule { }
