<aside class="border-gradient green filter-container">
	<div class="title separator">
		Filter Par
		<div class="display-phone filter-drop">
			<i *ngIf="!open" (click)="open=true" class="fa-sharp fa-solid fa-chevron-down"></i>
			<i *ngIf="open" (click)="open=false" class="fa-sharp fa-solid fa-chevron-up"></i>
		</div>
	</div>

	<div [ngClass]="{open: open}" class="filters">
		<!-- nombre de joueurs -->
		<div class="filter">
			<div class="title">
				Nombre de joueurs
			</div>
			<div class="form-check">
			  <input class="form-check-input" type="checkbox" value="" id="filter-players-1">
			  <label class="form-check-label" for="filter-players-1">1</label>
			</div>
			<div class="form-check">
			  <input class="form-check-input" type="checkbox" value="" id="filter-players-2">
			  <label class="form-check-label" for="filter-players-2">2</label>
			</div>
			<div class="form-check">
			  <input class="form-check-input" type="checkbox" value="" id="filter-players-3">
			  <label class="form-check-label" for="filter-players-3">3</label>
			</div>
			<div class="form-check">
			  <input class="form-check-input" type="checkbox" value="" id="filter-players-4">
			  <label class="form-check-label" for="filter-players-4">4</label>
			</div>
			<div class="form-check">
			  <input class="form-check-input" type="checkbox" value="" id="filter-players-5">
			  <label class="form-check-label" for="filter-players-5">5+</label>
			</div>

		</div>

		<!-- Difficulté -->
		<div class="filter">
			<div class="title">
				Difficulté
			</div>
			<div class="form-check">
			  <input class="form-check-input" type="checkbox" value="" id="filter-diffculty-1">
			  <label class="form-check-label" for="filter-diffculty-1">Débutant</label>
			</div>
			<div class="form-check">
			  <input class="form-check-input" type="checkbox" value="" id="filter-diffculty-2">
			  <label class="form-check-label" for="filter-diffculty-2">Familial</label>
			</div>
			<div class="form-check">
			  <input class="form-check-input" type="checkbox" value="" id="filter-diffculty-3">
			  <label class="form-check-label" for="filter-diffculty-3">Intermédiaire</label>
			</div>
			<div class="form-check">
			  <input class="form-check-input" type="checkbox" value="" id="filter-diffculty-4">
			  <label class="form-check-label" for="filter-diffculty-4">Avancé</label>
			</div>
			<div class="form-check">
			  <input class="form-check-input" type="checkbox" value="" id="filter-diffculty-5">
			  <label class="form-check-label" for="filter-diffculty-5">Expert</label>
			</div>
		</div>

		<!-- age -->
		<div class="filter">
			<div class="title">
				Age
			</div>
			<div class="form-check">
			  <input class="form-check-input" type="radio" value="3" name="filter-age" id="filter-age-3">
			  <label class="form-check-label" for="filter-age-3">3+</label>
			</div>
			<div class="form-check">
			  <input class="form-check-input" type="radio" value="5" name="filter-age" id="filter-age-5">
			  <label class="form-check-label" for="filter-age-5">5+</label>
			</div>
			<div class="form-check">
			  <input class="form-check-input" type="radio" value="10" name="filter-age" id="filter-age-10">
			  <label class="form-check-label" for="filter-age-10">10+</label>
			</div>
		</div>

		<!-- prix -->
		<div class="filter">
			<div class="title">
				Prix
			</div>
			<div class="form-floating mb-3">
			  <input type="number" class="form-control" id="min-price" placeholder="Min">
			  <label for="min-price">Min</label>
			</div>
			<div class="form-floating mb-3">
			  <input type="number" class="form-control" id="max-price" placeholder="Max">
			  <label for="max-price">Max</label>
			</div>
		</div>
	</div>
</aside>