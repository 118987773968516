import { Component, OnInit } from '@angular/core';
import { Product } from '../../../../models/product';

@Component({
  selector: 'gob-home-selection',
  templateUrl: './home-selection.component.html',
  styleUrls: ['./home-selection.component.css']
})
export class HomeSelectionComponent implements OnInit {
  products: Product[]=[];
  ind: number=0;

  constructor() { }

  ngOnInit(): void {
    this.products=Product.list();
  }

  next(){
    if(this.ind+1!=this.products.length)
      this.ind++;
  }

  prev(){
    this.ind--;
    if(this.ind<0)
      this.ind=0;
  }

}
