<div [ngClass]="{open: open}" class="menu-contrainer">
	<div class="display-phone drop-menu">
		<img class="logo" src="assets/img/logo/logo.png" alt="Caverne du Gobelin Logo"/>
		<i (click)="open=!open" class="burger fa-solid fa-bars"></i>
	</div>
	<div class="nav-container">
		<img class="logo hidden-phone" src="assets/img/logo/logo.png" alt="Caverne du Gobelin Logo"/>
		<nav *ngIf="!isAdmin" class="navbar navbar-expand-lg">
			<div class="collapse navbar-collapse show">
				<ul class="navbar-nav me-auto mb-2 mb-lg-0">

					<!-- jeux de société -->
					<li class="nav-item dropdown">
			          <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
			            Jeux de société
			          </a>
			          <ul class="dropdown-menu">
			            <li><a class="dropdown-item" href="#">Action</a></li>
			            <li><a class="dropdown-item" href="#">Another action</a></li>
			            <li><hr class="dropdown-divider"></li>
			            <li><a class="dropdown-item" href="#">Something else here</a></li>
			          </ul>
			        </li>

			        <!-- jeux de cartes -->
					<li class="nav-item dropdown">
			          <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
			            Jeux de carte
			          </a>
			          <ul class="dropdown-menu">
			            <li><a class="dropdown-item" href="#">Action</a></li>
			            <li><a class="dropdown-item" href="#">Another action</a></li>
			            <li><hr class="dropdown-divider"></li>
			            <li><a class="dropdown-item" href="#">Something else here</a></li>
			          </ul>
			        </li>

			        <!-- jeux de role -->
					<li class="nav-item dropdown">
			          <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
			            Jeux de rôle
			          </a>
			          <ul class="dropdown-menu">
			            <li><a class="dropdown-item" href="#">Action</a></li>
			            <li><a class="dropdown-item" href="#">Another action</a></li>
			            <li><hr class="dropdown-divider"></li>
			            <li><a class="dropdown-item" href="#">Something else here</a></li>
			          </ul>
			        </li>

			        <!-- accessoires -->
					<li class="nav-item dropdown">
			          <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
			            Accessoire
			          </a>
			          <ul class="dropdown-menu">
			            <li><a class="dropdown-item" href="#">Action</a></li>
			            <li><a class="dropdown-item" href="#">Another action</a></li>
			            <li><hr class="dropdown-divider"></li>
			            <li><a class="dropdown-item" href="#">Something else here</a></li>
			          </ul>
			        </li>

			        <!-- nos animations -->
			        <li class="nav-item">
			          <a class="nav-link" >Nos animations</a>
			        </li>

			        <!-- nos cafés jeux -->
			        <li class="nav-item">
			          <a class="nav-link" >Café jeux</a>
			        </li>
			    </ul>
			</div>
		</nav>
		<nav *ngIf="isAdmin" class="navbar navbar-expand-lg">
			<div class="collapse navbar-collapse show">
				Connecté
			</div>
		</nav>
	</div>
	<aside>
		<div class="user-container">
			<a href="#" class="accompte" title="Mon compte">
				Mon compte 
				<i class="fa-light fa-user"></i>
			</a>
			<a href="#" title="Panier">
				Panier
				<span class="counter-cart">{{count}}</span>
			</a>
		</div>
		<a title="Recherchez" class="search-container">
			Recheche
			<i class="fa-light fa-magnifying-glass"></i>
		</a>
	</aside>
</div>
