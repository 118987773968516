import { Routes } from '@angular/router';
import { HomePageComponent } from './components/page/home/home-page/home-page.component';
import { ProductListComponent } from './components/page/products/product-list/product-list.component';
import { ProductPageComponent } from './components/page/products/product-page/product-page.component';
import { BarPageComponent } from './components/page/bar/bar-page/bar-page.component';
import { ConnectPageComponent } from './components/page/connect/connect-page/connect-page.component';
import { HomeComponent as HomeAdmin } from './components/admin/home/home.component';

export const ROUTES: Routes=[
	{path: '', pathMatch: 'full', redirectTo: '/accueil'},
	{path: 'accueil', component: HomePageComponent},
	{path: 'products', component: ProductListComponent},
	{path: 'products/:id', component: ProductPageComponent},
	{path: 'cafe-jeux', component: BarPageComponent},
	{path: 'login', component: ConnectPageComponent},
	{path: 'login-home', component: HomeAdmin, data: {isAdmin: true}}
]