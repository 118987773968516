<gob-header></gob-header>
<div class="block">
	<div class="product-container">
		<div class="diapo-container">
			<div class="img-container">
				<img [src]="product?.images[indImg]" [alt]="product?.name"/>
				<span *ngIf="product.banner" class="banner" [style.backgroundColor]="product.color">{{product.banner}}</span>
			</div>
			<gob-pagination [activePage]="indImg" text="" (change)="indImg=$event" [max]="product?.images.length"></gob-pagination>
		</div>
		<gob-product-detail [product]="product"></gob-product-detail>
	</div>
	<div class="details-container">
		<ul class="nav nav-tabs">
		  <li class="nav-item">
		    <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#detail-tab-pane" type="button">Détails</button>
		  </li>
		  <li class="nav-item">
		    <button class="nav-link" data-bs-toggle="tab" data-bs-target="#review-tab-pane" type="button">Avis</button>
		  </li>
		</ul>
		<div class="tab-content" id="detail-product-container">
		  <div class="tab-pane fade show active" id="detail-tab-pane" [innerHTML]="product.details"></div>
		  <div class="tab-pane fade" id="review-tab-pane">Avis</div>
		</div>
	</div>
	<div class="linked-contrainer">
		<h2>On vous recommande</h2>
		<div class="linked-product">
			<div *ngFor="let p of product.linked" class="product">
				<gob-product-card [product]="p"></gob-product-card>
			</div>
		</div>
	</div>
</div>