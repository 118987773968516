import { Component, OnInit } from '@angular/core';
import { Product } from '../../../../models/product';

@Component({
  selector: 'gob-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {
  products: Product[]=[];
  isGrid: boolean=true;

  constructor() {
    this.products=Product.list();
  }

  ngOnInit(): void {
  }

}
