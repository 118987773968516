<article class="block">
	<div class="header-selection">
		<header>
			<h2 class="title"> Notre <span class="go-line">Sélection</span></h2>
		</header>
		<div class="selection-nav">
			<div class="categ-container">
				<a class="selected" title="Nouveautés">Nouveautés</a>
				<a title="Coup de coeur">Coup de coeur</a>
				<a title="Promos">Promos</a>
			</div>
			<div class="arrow-container">
				<a title="precedent" (click)="prev()"><i class="border-gradient-circle fa-solid fa-chevron-left"></i></a>
				<a title="suivant" (click)="next()"><i class="border-gradient-circle fa-solid fa-chevron-right"></i></a>
			</div>
		</div>
	</div>
	<div class="products-container">
		<gob-product-selection [products]="products" [ind]="ind"></gob-product-selection>
	</div>
</article>