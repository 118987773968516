<div class="products-container">
	<div *ngFor="let product of products" class="product">
		<a class="img-container" [title]="product.name">
			<img src="assets/img/test/{{product.img}}" [alt]="product.name"/>
			<span *ngIf="product.banner" class="banner" [style.backgroundColor]="product.color">{{product.banner}}</span>
		</a>
		<div class="info-container">
			<a class="product-link" [title]="product.name">
				<span class="product-title">
					{{product.name}}
				</span>
				<gob-stars [stars]="product.stars"></gob-stars>
				<span class="product-price">
					<span>
						{{formatPrice(product.price)}}€
					</span>
					<span>
						<i class="fad fa-timer"></i>{{product.duration}}
					</span>
					<span>
						<i class="fad fa-cake-candles"></i>{{product.age}}+
					</span>
					<span>
						<i class="fad fa-swords"></i>{{product.players}}
					</span>
				</span>
				<span class="subtitle truncate-2l">
					{{product.subtitle}}
				</span>
			</a>
			<div class="add-cart">
				<button type="button" class="btn green">Ajouter au panier</button>
			</div>
		</div>
	</div>
</div>
