<div class="detail-container">
	<h1>{{product.name}}</h1>
	<div class="stars">
		<gob-stars [stars]="product.stars"></gob-stars>
	</div>
	<div class="product-price">
		{{formatPrice(product.price)}}€
	</div>
	<div class="product-subtitle">
		{{product.subtitle}}
	</div>
	<div class="elements">
		<div>
			<div *ngIf="product.duration" class="element">Durée</div>
			<div *ngIf="product.players" class="element">Joueurs</div>
			<div *ngIf="product.age" class="element">Age</div>
			<div *ngIf="product.tags.length" class="element">Tags</div>
			<div class="element">Disponibilité</div>
			<div class="element">Quantité</div>
		</div>
		<div>
			<div *ngIf="product.duration" class="element"> : {{product.duration}}min</div>
			<div *ngIf="product.players" class="element"> : {{product.players}}</div>
			<div *ngIf="product.age" class="element"> : {{product.age}}+</div>
			<div *ngIf="product.tags.length" class="element"> : {{product.tags.join(', ')}}</div>
			<div class="element"> : <span class="dispo">En stock</span></div>
			<div class="element">
				<div class="input-group mb-3">
				  <button class="input-group-text">
					<i class="fa-solid fa-minus"></i>
				  </button>
			      <input type="number" class="qte form-control"/>
				  <button class="input-group-text">
					<i class="fa-solid fa-plus"></i>
				  </button>
				</div>
			</div>
		</div>
	</div>
	<div class="add-cart">
		<button type="button" class="btn green">Ajouter au panier</button>
	</div>
	<div *ngIf="product.video" class="video">
		<div class="accordion" id="product-video-container">
		  <div class="accordion-item">
		    <h2 class="accordion-header">
		      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#product-video">
		      	Vidéo
		      </button>
		    </h2>
		    <div id="product-video" class="accordion-collapse collapse" data-bs-parent="#product-video-container">
		      <div class="accordion-body">
				<gob-youtube [id]="product.video"></gob-youtube>
		      </div>
		    </div>
		  </div>
		</div>
	</div>
</div>