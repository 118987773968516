import { Component, OnInit } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';

@Component({
  selector: 'gob-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  open: boolean=false;
  count: number=20;
  isAdmin: boolean=false;


  constructor(private router: Router) {
    this.router.events.subscribe(data => {
      if(data instanceof ActivationEnd && data.snapshot.data.isAdmin)
        this.isAdmin=true;
      else if(data instanceof ActivationEnd)
        this.isAdmin=false;
    });
  }

  ngOnInit(): void {
  }

}
