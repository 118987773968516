<div class="product-img">
	<div class="img-container">
		<img src="assets/img/test/{{product.img}}" [alt]="product.name"/>
	</div>
	<div *ngIf="product.banner" [style.backgroundColor]="product.color" class="banner">
		{{product.banner}}
	</div>
	<div class="product-info">
		<a title="En savoir plus" class="info border-gradient-circle">
			<i class="fa-light fa-magnifying-glass-plus"></i>
		</a>
		<button type="button" class="btn btn-dark">Panier</button>
	</div>
</div>
<a class="products-price-container">
	<span class="product-title">
		{{product.name}}
	</span>
	<div class="subtitle truncate-2l">
		{{product.subtitle}}
	</div>
	<span class="product-stars">
		<gob-stars [stars]="product.stars"></gob-stars>
	</span>
	<span class="product-price">
		{{formatPrice(product.price)}}€
	</span>
</a>