import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ProductListComponent } from './product-list/product-list.component';
import { PartialModule } from '../partial/partial.module';
import { UiModule } from '../../core/ui/ui.module';
import { FilterProductComponent } from './filter-product/filter-product.component';
import { ListCardComponent } from './list-card/list-card.component';
import { ListLineComponent } from './list-line/list-line.component';
import { ProductPageComponent } from './product-page/product-page.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ReviewComponent } from './review/review.component';

@NgModule({
  declarations: [
    ProductListComponent,
    FilterProductComponent,
    ListCardComponent,
    ListLineComponent,
    ProductPageComponent,
    ProductDetailComponent,
    ReviewComponent
  ],
  imports: [
    CommonModule,
    PartialModule,
    UiModule,
    RouterModule
  ],
  exports: [
    ProductListComponent
  ]
})
export class ProductsModule { }
