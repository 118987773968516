<!-- <span class="product-stars">
	<i *ngFor="let i of [].constructor(stars)" class="stars fad fa-stars"></i>
	<i *ngFor="let i of [].constructor(5-stars)" class="stars fa-thin fa-stars"></i>
</span> -->
<span class="gob product-stars">
	<span *ngFor="let i of [].constructor(stars)" class="stars">
		<img src="/assets/img/skin/star.png" alt="gobelin"/>
	</span>
	<span *ngFor="let i of [].constructor(5-stars)" class="stars">
		<img src="/assets/img/skin/star-empty.png" alt="gobelin"/>
	</span>
</span>