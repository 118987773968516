<h1 class="force-title">Mot de passe oublié</h1>
<form class="form-log">
	<div class="form-floating mb-3">
	  <input type="text" class="form-control" id="log-log" placeholder="Login">
	  <label for="log-log">Login</label>
	</div>
	<button type="submit" class="btn green">Valider</button>
</form>
<div class="form-footer">
	<a (click)="login.emit(true)">Retour à la page de connexion</a>
</div>
