import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { ResponseApi } from '../models/response-api';

@Injectable({
	providedIn: 'root'
})
export class ApiService{
	constructor(private http: HttpClient){ }

	get<T>(url: string){
		return this.http.get<ResponseApi>(url)
			.pipe(
				catchError(error => { throw error.error.error; }),
				map(data => data.data)
			);
	}

	post<T>(url: string, data: any){
		return this.http.post<ResponseApi>(url, data)
			.pipe(
				catchError(error => { throw error.error.error; }),
				map(data => data.data)
			);
	}
}