<gob-header title="Jeux de société"></gob-header>
<div class="block products-contrainer">
	<div class="filter">
		<gob-filter-product></gob-filter-product>
	</div>
	<div class="list-container">
		<div class="list-choice">
			<button (click)="isGrid=true" type="button" [ngClass]="{selected: isGrid}" class="btn btn-secondary"><i class="fad fa-grid"></i></button>
			<button (click)="isGrid=false" type="button" [ngClass]="{selected: !isGrid}" class="btn btn-secondary"><i class="fad fa-line-height"></i></button>
		</div>
		<gob-list-card *ngIf="isGrid" [products]="products"></gob-list-card>
		<gob-list-line *ngIf="!isGrid" [products]="products"></gob-list-line>
		<div class="page-container">
			<gob-pagination></gob-pagination>
		</div>
	</div>
</div>