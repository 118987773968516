<gob-back (back)="login.emit(true)"></gob-back>	
<h1 class="force-title">Créez votre compte</h1>
<form class="form-log">
	<!-- nom -->
	<div class="form-floating mb-3">
	  <input type="text" class="form-control" id="log-name" placeholder="Nom">
	  <label for="log-name">Nom</label>
	</div>

	<!-- prénom -->
	<div class="form-floating mb-3">
	  <input type="text" class="form-control" id="log-lastname" placeholder="Prénom">
	  <label for="log-lastname">Prénom</label>
	</div>

	<!-- date de naissance -->
	<div class="form-floating mb-3">
	  <input type="date" class="form-control" id="log-birth" placeholder="Date de naissance">
	  <label for="log-birth">Date de naissance</label>
	</div>

	<!-- email -->
	<div class="form-floating mb-3">
	  <input type="email" class="form-control" id="log-mail" placeholder="E-mail">
	  <label for="log-mail">E-mail</label>
	</div>

	<!-- pass -->
	<div class="form-floating mb-3">
	  <input type="password" class="form-control" id="log-pass" placeholder="Mot de passe">
	  <label for="log-pass">Mot de passe</label>
	</div>

	<!-- tel -->
	<div class="form-floating mb-3">
	  <input type="tel" class="form-control" id="log-tel" placeholder="Téléphone">
	  <label for="log-tel">Téléphone</label>
	</div>
	<button type="submit" class="btn green">Valider</button>
</form>
