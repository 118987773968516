import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { User } from '../models/User';

@Injectable({
	providedIn: 'root'
})
export class UserService{
	_user: User;
	_cession: string;

	//cosntructor
	constructor(private api: ApiService){ }

	//accessor
	get user(): User{
		this._user=JSON.parse(localStorage.getItem('user'));
		return this._user;
	}

	set user(value: User){
		this._user=value;
	 	localStorage.setItem('user', JSON.stringify(this._user));
	}

	get cession(): string{
		this._cession=localStorage.getItem('cession');
		return this._cession;
	}

	set cession(value: string){
		this._cession=value;
	 	localStorage.setItem('cession', this._cession);
	}

	login(email: string, pass: string){
		return this.api.post<any>(environment.servicesUrl+'/users/connect', {email, pass})
			.pipe(
				tap(data => this.cession=data.cession),
				tap(data => this.user=data.user)
			);
	}
}