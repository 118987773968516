import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'gob-sign-form',
  templateUrl: './sign-form.component.html',
  styleUrls: ['./sign-form.component.css']
})
export class SignFormComponent implements OnInit {
  @Output() readonly login: EventEmitter<boolean>=new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

}
