import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { FormNewsComponent } from './form-news/form-news.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { UiModule } from '../../core/ui/ui.module';


@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    FormNewsComponent,
    ProductCardComponent
  ],
  imports: [
    CommonModule,
    UiModule
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    FormNewsComponent,
    ProductCardComponent
  ]
})
export class PartialModule { }
