import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'gob-forgot-form',
  templateUrl: './forgot-form.component.html',
  styleUrls: ['./forgot-form.component.css']
})
export class ForgotFormComponent implements OnInit {
  @Output() login: EventEmitter<boolean>=new EventEmitter<boolean>();
  
  constructor() { }

  ngOnInit(): void {
  }

}
